import { getInstitutes, createInstitute, updateInstitute, removeInstitute } from './instituteExtra';
import instituteSlice from './instituteSlice';

const { setInstitutePagination, setInstituteSort } = instituteSlice.actions;

export const setInstitutePaginationAndFetch = (data) => (dispatch) => {
  dispatch(setInstitutePagination(data));
  dispatch(getInstitutes());
};

export const setInstituteSortAndFetch = (sort = '') => (dispatch) => {
  dispatch(setInstituteSort(sort));
  dispatch(getInstitutes());
};

export const createInstituteAndFetch = (institute) => async (dispatch) => {
  await dispatch(createInstitute({ institute }));
  dispatch(getInstitutes());
};

export const updateInstituteAndFetch = (institute) => async (dispatch) => {
  await dispatch(updateInstitute({ institute }));
  dispatch(getInstitutes());
};

export const removeInstituteAndFetch = (instituteId) => async (dispatch) => {
  await dispatch(removeInstitute({ id: instituteId }));
  dispatch(getInstitutes());
};
