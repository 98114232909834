import client from '../utils/client';

export const fetchAllFeatureRequests = ({ sort, pagination }) => {
  return client.get('/feature_request', {
    params: { sort, page: pagination.number, size: pagination.size },
  });
};

export const createFeatureRequest = (data) => {
  return client.post('/feature_request', data);
};

export const uploadFeatureRequestAttachments = (id, data) => {
  const formData = new FormData();
  for (const file of data) {
    formData.append('attachments', file);
  }

  return client.post(`/feature_request/${id}/attachment`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getFeatureRequestAttachment = (id) => {
  return client.get(`/feature_request/attachment/${id}`, { responseType: "blob" });
};

export const removeFeatureRequest = (id) => {
  return client.delete(`/feature_request/${id}`);
};
