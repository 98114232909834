import instituteSlice from './instituteSlice';

export { getInstitutes, getAllInstitutes, createUserInstitute } from './instituteExtra';

export const { setInstitutePagination, clearInstituteData, setInstituteSort } = instituteSlice.actions;

export * from './instituteActions';
export * from './instituteHook';

export default instituteSlice.reducer;
