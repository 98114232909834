import { createSelector } from '@reduxjs/toolkit';

export const selectFeatureRequestState = (state) => state.featureRequest;

export const selectFeatureRequestContent = createSelector(selectFeatureRequestState, (state) => state.content);
export const selectFeatureRequestPagination = createSelector(selectFeatureRequestState, (state) => state.pagination);
export const selectFeatureRequestTotalElements = createSelector(
  selectFeatureRequestState,
  (state) => state.totalElements,
);
export const selectFeatureRequestLoading = createSelector(selectFeatureRequestState, (state) => state.loading);
