import featureRequestSlice from './featureRequestSlice';

// eslint-disable-next-line max-len
export { getFeatureRequests, createFeatureRequest, uploadFeatureRequestAttachments, getFeatureRequestAttachment } from './featureRequestExtra';

export const { setFeatureRequestPagination, clearFeatureRequestData, setFeatureRequestSort } = featureRequestSlice.actions;

export * from './featureRequestActions';
export * from './featureRequestHook';

export default featureRequestSlice.reducer;
