/* eslint-disable max-len */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { featureRequestService } from '../../services';

// extra actions
export const getFeatureRequests = createAsyncThunk('featureRequest/getFeatureRequests', async (_, thunkAPI) => {
  const { pagination, sort } = thunkAPI.getState().featureRequest;
  const { data } = await featureRequestService.fetchAllFeatureRequests({ pagination, sort });
  return data;
});

export const createFeatureRequest = createAsyncThunk('featureRequest/createFeatureRequest', async (featureRequest, thunkAPI) => {
  try {
    const { data } = await featureRequestService.createFeatureRequest(featureRequest);
    return data;
  } catch (e) {
    throw thunkAPI.rejectWithValue(e.response);
  }
});

export const uploadFeatureRequestAttachments = createAsyncThunk('featureRequest/uploadFeatureRequestAttachments', async (featureRequest, thunkAPI) => {
  try {
    if (featureRequest.attachments.length) {
      await featureRequestService.uploadFeatureRequestAttachments(featureRequest.id, featureRequest.attachments);
    }
  } catch (e) {
    throw thunkAPI.rejectWithValue(e.response);
  }
});

export const getFeatureRequestAttachment = createAsyncThunk('featureRequest/getFeatureRequestAttachment', async (id) => {
  const { data } = await featureRequestService.getFeatureRequestAttachment(id);
  return data;
});

export const removeFeatureRequest = createAsyncThunk('featureRequest/removeFeatureRequest', async ({ id }) => {
  await featureRequestService.removeFeatureRequest(id);
});

// extra reducer
export default {
  [getFeatureRequests.pending]: (state) => {
    state.loading = true;
  },
  [getFeatureRequests.fulfilled]: (state, action) => {
    const { content, totalElements } = { ...action.payload };

    state.content = content;
    state.totalElements = totalElements;
    state.loading = false;
  },
  [getFeatureRequests.rejected]: (state) => {
    state.loading = false;
    state.content = [];
  },

  [createFeatureRequest.pending]: (state) => {
    state.loading = true;
  },
  [createFeatureRequest.fulfilled]: (state) => {
    state.loading = false;
  },
  [createFeatureRequest.rejected]: (state, action) => {
    state.loading = false;

    notification.error({
      message: 'Feedback creation',
      description: action?.payload?.data?.message || 'Something was wrong',
    });
  },

  [uploadFeatureRequestAttachments.pending]: (state) => {
    state.loading = true;
  },
  [uploadFeatureRequestAttachments.fulfilled]: (state) => {
    state.loading = false;

    notification.success({
      message: 'Feedback creation',
      description: 'Feedback was created successfully',
    });
  },
  [uploadFeatureRequestAttachments.rejected]: (state, action) => {
    state.loading = false;

    notification.error({
      message: 'Feedback creation',
      description: action?.payload?.data?.message || 'Something was wrong',
    });
  },

  [getFeatureRequestAttachment.pending]: (state) => {
    state.loading = true;
  },
  [getFeatureRequestAttachment.fulfilled]: (state) => {
    state.loading = false;
  },
  [getFeatureRequestAttachment.rejected]: (state) => {
    state.loading = false;

    notification.error({
      message: 'Attachment error',
      description: 'Something was wrong',
    });
  },

  [removeFeatureRequest.pending]: (state) => {
    state.loading = true;
  },
  [removeFeatureRequest.fulfilled]: (state) => {
    state.loading = false;

    notification.success({
      message: 'Feedback remove',
      description: 'Feedback was removed successfully',
    });
  },
  [removeFeatureRequest.rejected]: (state) => {
    state.loading = false;

    notification.error({
      message: 'Feedback remove',
      description: 'Something was wrong',
    });
  },
};
