import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { instituteService } from '../../services';

// extra actions
export const getInstitutes = createAsyncThunk('institute/getInstitutes', async (_, thunkAPI) => {
  const { pagination, sort } = thunkAPI.getState().institute;
  const { data } = await instituteService.fetchAllInstitutes({ pagination, sort });

  return data;
});

export const getAllInstitutes = createAsyncThunk('institute/getAllInstitutes', async () => {
  const { data } = await instituteService.fetchInstitutes();
  return data;
});

export const createInstitute = createAsyncThunk('institute/createInstitute', async ({ institute }, thunkAPI) => {
  try {
    await instituteService.createInstitute(institute);
  } catch (e) {
    throw thunkAPI.rejectWithValue(e.response);
  }
});

export const createUserInstitute = createAsyncThunk('institute/createUserInstitute', async (institute, thunkAPI) => {
  try {
    const { data } = await instituteService.createInstitute(institute);
    return data;
  } catch (e) {
    throw thunkAPI.rejectWithValue(e.response);
  }
});

export const updateInstitute = createAsyncThunk('institute/updateInstitute', async ({ institute }, thunkAPI) => {
  try {
    await instituteService.updateInstitute(institute);
  } catch (e) {
    throw thunkAPI.rejectWithValue(e.response);
  }
});

export const removeInstitute = createAsyncThunk('institute/removeInstitute', async ({ id }) => {
  await instituteService.removeInstitute(id);
});

// extra reducer
export default {
  [getInstitutes.pending]: (state) => {
    state.loading = true;
  },
  [getInstitutes.fulfilled]: (state, action) => {
    const { content, totalElements } = { ...action.payload };

    state.content = content;
    state.totalElements = totalElements;
    state.loading = false;
  },
  [getInstitutes.rejected]: (state) => {
    state.loading = false;
    state.content = [];
  },

  [getAllInstitutes.pending]: (state) => {
    state.loading = true;
  },
  [getAllInstitutes.fulfilled]: (state, action) => {
    state.loading = false;
    state.content = action.payload;
  },
  [getAllInstitutes.rejected]: (state) => {
    state.loading = false;
    state.content = [];
  },

  [createInstitute.pending]: (state) => {
    state.loading = true;
  },
  [createInstitute.fulfilled]: (state) => {
    state.loading = false;

    notification.success({
      message: 'Institute creation',
      description: 'Institute was created successfully',
    });
  },
  [createInstitute.rejected]: (state, action) => {
    state.loading = false;

    if (action?.payload?.status === 404) {
      notification.error({
        message: 'Institute creation',
        description: action?.payload?.data?.message || 'Something was wrong',
      });
    } else {
      notification.error({
        message: 'Institute creation',
        description: 'Something was wrong',
      });
    }
  },

  [createUserInstitute.pending]: (state) => {
    state.loading = true;
  },
  [createUserInstitute.fulfilled]: (state, action) => {
    state.loading = false;
    state.content = [...state.content, action.payload];

    notification.success({
      message: 'Institute creation',
      description: 'Institute was created successfully',
    });
  },
  [createUserInstitute.rejected]: (state, action) => {
    state.loading = false;

    if (action?.payload?.status === 404) {
      notification.error({
        message: 'Institute creation',
        description: action?.payload?.data?.message || 'Something was wrong',
      });
    } else {
      notification.error({
        message: 'Institute creation',
        description: 'Something was wrong',
      });
    }
  },

  [updateInstitute.pending]: (state) => {
    state.loading = true;
  },
  [updateInstitute.fulfilled]: (state) => {
    state.loading = false;

    notification.success({
      message: 'Institute update',
      description: 'Institute was updated successfully',
    });
  },
  [updateInstitute.rejected]: (state, action) => {
    state.loading = false;

    if (action?.payload?.status === 404) {
      notification.error({
        message: 'Institute update',
        description: action?.payload?.data?.message || 'Something was wrong',
      });
    } else {
      notification.error({
        message: 'Institute update',
        description: 'Something was wrong',
      });
    }
  },

  [removeInstitute.pending]: (state) => {
    state.loading = true;
  },
  [removeInstitute.fulfilled]: (state) => {
    state.loading = false;

    notification.success({
      message: 'Institute remove',
      description: 'Institute was removed successfully',
    });
  },
  [removeInstitute.rejected]: (state) => {
    state.loading = false;

    notification.error({
      message: 'Institute remove',
      description: 'Something was wrong',
    });
  },
};
