import { createSlice } from '@reduxjs/toolkit';
import reducers, { initialState } from './featureRequestReducer';
import extraReducers from './featureRequestExtra';

const featureRequestSlice = createSlice({
  name: 'featureRequest',
  initialState,
  reducers,
  extraReducers,
});

export default featureRequestSlice;
