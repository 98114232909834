import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  clearUserData,
  getUsers,
  useUserState,
  setUserPaginationAndFetch,
  setUserSortAndFetch,
  createUserAndFetch,
  updateUserAndFetch,
  updateUserPasswordAndFetch,
  updateUserCreditsAndFetch,
  getUserCredits,
  removeUserAndFetch,
} from '../store';

const useUser = () => {
  const dispatch = useDispatch();
  const { content, pagination, sort, totalElements, loading } = useUserState();

  const get = useCallback(() => dispatch(getUsers()), [dispatch]);

  const setPagination = useCallback((p) => dispatch(setUserPaginationAndFetch(p)), [dispatch]);
  const setSort = useCallback((s) => dispatch(setUserSortAndFetch(s)), [dispatch]);
  const createUser = useCallback((u) => dispatch(createUserAndFetch(u)), [dispatch]);
  const updateUser = useCallback((u) => dispatch(updateUserAndFetch(u)), [dispatch]);
  const updateUserPassword = useCallback((p) => dispatch(updateUserPasswordAndFetch(p)), [dispatch]);
  const updateUserCredits = useCallback((v) => dispatch(updateUserCreditsAndFetch(v)), [dispatch]);
  const getCredits = useCallback(() => dispatch(getUserCredits()), [dispatch]);
  const removeUser = useCallback((u) => dispatch(removeUserAndFetch(u)), [dispatch]);

  const clearData = useCallback(() => dispatch(clearUserData()), [dispatch]);

  const methods = {
    get,
    setPagination,
    setSort,
    createUser,
    updateUser,
    updateUserPassword,
    updateUserCredits,
    getCredits,
    removeUser,
    clearData,
  };

  return { content, pagination, sort, totalElements, loading, methods };
};

export default useUser;
