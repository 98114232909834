// eslint-disable-next-line max-len
import { getFeatureRequests, createFeatureRequest, uploadFeatureRequestAttachments, getFeatureRequestAttachment, removeFeatureRequest } from './featureRequestExtra';
import featureRequestSlice from './featureRequestSlice';

const { setFeatureRequestPagination, setFeatureRequestSort } = featureRequestSlice.actions;

export const setFeatureRequestPaginationAndFetch = (data) => (dispatch) => {
  dispatch(setFeatureRequestPagination(data));
  dispatch(getFeatureRequests());
};

export const setFeatureRequestSortAndFetch = (sort = '') => (dispatch) => {
  dispatch(setFeatureRequestSort(sort));
  dispatch(getFeatureRequests());
};

export const createFeatureRequestAndUploadAttachments = (data) => async (dispatch) => {
  const featureRequest = {
    isBug: data.type === "bug",
    request: data.request,
  };
  const attachments = data.fileList?.map(({ originFileObj }) => originFileObj);
  const { payload } = await dispatch(createFeatureRequest(featureRequest));
  if (payload?.id) {
    dispatch(uploadFeatureRequestAttachments({id: payload.id, attachments}));
  }
};

export const getFeatureRequestAttachments = (ids) => async (dispatch) => {
  const attachments = [];
  await Promise.allSettled(ids.map(async (id) => {
    const { payload } = await dispatch(getFeatureRequestAttachment(id));
    if (payload) {
      const objectURL = URL.createObjectURL(payload);
      attachments.push(objectURL);
      return Promise.resolve(objectURL);
    }
    return Promise.reject(id);
  }));
  return attachments;
};

export const removeFeatureRequestAndFetch = (id) => async (dispatch) => {
  await dispatch(removeFeatureRequest({ id }));
  dispatch(getFeatureRequests());
};
