import { ConfigProvider, notification } from 'antd';


ConfigProvider.config({
  theme: {
    primaryColor: '#00afb7',
  },
});

notification.config({
  placement: 'top',
});

export default ConfigProvider;
