import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  clearInstituteData,
  getInstitutes,
  useInstituteState,
  setInstitutePaginationAndFetch,
  setInstituteSortAndFetch,
  createInstituteAndFetch,
  updateInstituteAndFetch,
  removeInstituteAndFetch,
  getAllInstitutes,
  createUserInstitute as createInstituteUser,
} from '../store';

const useInstitute = () => {
  const dispatch = useDispatch();
  const { content, pagination, sort, totalElements, loading } = useInstituteState();

  const get = useCallback(() => dispatch(getInstitutes()), [dispatch]);

  const setPagination = useCallback((p) => dispatch(setInstitutePaginationAndFetch(p)), [dispatch]);
  const setSort = useCallback((s) => dispatch(setInstituteSortAndFetch(s)), [dispatch]);
  const createInstitute = useCallback((i) => dispatch(createInstituteAndFetch(i)), [dispatch]);
  const createUserInstitute = useCallback((i) => dispatch(createInstituteUser(i)), [dispatch]);
  const updateInstitute = useCallback((i) => dispatch(updateInstituteAndFetch(i)), [dispatch]);
  const removeInstitute = useCallback((i) => dispatch(removeInstituteAndFetch(i)), [dispatch]);
  const getAll = useCallback(() => dispatch(getAllInstitutes()), [dispatch]);

  const clearData = useCallback(() => dispatch(clearInstituteData()), [dispatch]);

  const methods = {
    get,
    setPagination,
    setSort,
    createInstitute,
    createUserInstitute,
    updateInstitute,
    removeInstitute,
    clearData,
    getAll,
  };

  return { content, pagination, sort, totalElements, loading, methods };
};

export default useInstitute;
