import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  clearFeatureRequestData,
  getFeatureRequests,
  useFeatureRequestState,
  setFeatureRequestPaginationAndFetch,
  setFeatureRequestSortAndFetch,
  createFeatureRequestAndUploadAttachments,
  getFeatureRequestAttachments,
  removeFeatureRequestAndFetch,
} from '../store';

const useFeatureRequest = () => {
  const dispatch = useDispatch();
  const { content, pagination, sort, totalElements, loading } = useFeatureRequestState();

  const get = useCallback(() => dispatch(getFeatureRequests()), [dispatch]);

  const setPagination = useCallback((p) => dispatch(setFeatureRequestPaginationAndFetch(p)), [dispatch]);
  const setSort = useCallback((s) => dispatch(setFeatureRequestSortAndFetch(s)), [dispatch]);
  const create = useCallback((f) => dispatch(createFeatureRequestAndUploadAttachments(f)), [dispatch]);
  const getAttachments = useCallback((ids) => dispatch(getFeatureRequestAttachments(ids)), [dispatch]);
  const remove = useCallback((i) => dispatch(removeFeatureRequestAndFetch(i)), [dispatch]);
  const clearData = useCallback(() => dispatch(clearFeatureRequestData()), [dispatch]);

  const methods = {
    get,
    setPagination,
    setSort,
    create,
    getAttachments,
    remove,
    clearData,
  };

  return { content, pagination, sort, totalElements, loading, methods };
};

export default useFeatureRequest;
