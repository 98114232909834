import client from '../utils/client';

export const changePasswordForCurrentUser = (data) => {
  return client.put('/user/reset', data);
};

export const changePasswordForUser = ({ id, data }) => {
  return client.put(`/user/reset/${id}`, data);
};

export const fetchAllUsers = ({ sort, pagination }) => {
  return client.get('/user', {
    params: { sort, page: pagination.number, size: pagination.size },
  });
};

export const createUser = (data) => {
  return client.post('/user', data);
};

export const updateUser = (data) => {
  return client.put('/user', data);
};

export const getUserCredits = () => {
  return client.get(`/credit/`);
};

export const updateUserCredits = ({ userId, credits }) => {
  return client.put(`/credit/${userId}`, credits, {
    headers: { 'Content-Type': 'application/json' },
  });
};

export const calculateFilesCost = (countOfTeeth) => {
  return client.post('/credit', countOfTeeth);
};

export const deductFilesCost = (countOfTeeth) => {
  return client.put('/credit', countOfTeeth);
};

export const removeUser = (id) => {
  return client.delete(`/user/${id}`);
};
