import client from '../utils/client';

export const fetchAllInstitutes = ({ sort, pagination }) => {
  return client.get('/institute', {
    params: { sort, page: pagination.number, size: pagination.size },
  });
};

export const fetchInstitutes = () => {
  return client.get('/institute/all');
};

export const createInstitute = (data) => {
  return client.post('/institute', data);
};

export const updateInstitute = (data) => {
  return client.put(`/institute/${data.id}`, data);
};

export const removeInstitute = (id) => {
  return client.delete(`/institute/${id}`);
};
