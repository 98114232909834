import { createSelector } from '@reduxjs/toolkit';

export const selectInstituteState = (state) => state.institute;

export const selectInstituteContent = createSelector(selectInstituteState, (state) => state.content);
export const selectInstitutePagination = createSelector(selectInstituteState, (state) => state.pagination);
export const selectInstituteTotalElements = createSelector(
  selectInstituteState,
  (state) => state.totalElements,
);
export const selectInstituteLoading = createSelector(selectInstituteState, (state) => state.loading);
